import { sendTrackingEvent } from '@/utils/eventTrackingUtils';
import Vue from 'vue';
import VueRouter from 'vue-router';

import authMiddleware from './middlewares/auth-middleware';
import hunterMiddleware from './middlewares/hunter-middleware';
import productsMiddleware from './middlewares/products-middleware';
import scansMiddleware from './middlewares/scans-middleware';
import homeMiddleware from './middlewares/home-middleware';
import hunterUpgradeMiddleware from './middlewares/hunter-upgrade-middleware';
import scansUpgradeMiddleware from './middlewares/scans-upgrade-middleware';
import productsUpgradeMiddleware from './middlewares/products-upgrade-middleware';
import ignoreRulesMiddleware from './middlewares/ignore-rules-middleware';
import mobileUnsupportMiddleware from './middlewares/mobile-unsupport-middleware';
import guestRedirectMiddleware from './middlewares/guest-redirect-middleware';
import registerAllowedMiddleware from './middlewares/register-allowed-middleware';
import parametricSearchMiddleware from './middlewares/parametric-search-middleware';

import nextFactory from './utils/middlewarePipeline';

const adminGeneral = () => import('@/pages/admin/tabs/general/general-page.vue');

const adminUsers = () => import('@/pages/admin/tabs/users/users-page.vue');

const settings = () => import('@/pages/admin/tabs/settings-page.vue');

const repositoryBrowser = () => import('@/pages/data-manager/tabs/repository-browser-page/repository-browser-page.vue');

const mfrInfo = () => import('@/pages/data-manager/tabs/mfr-info-page.vue');

const techData = () => import('@/pages/data-manager/tabs/tech-data-page/tech-data-page.vue');

const searchAPI = () => import('@/pages/data-manager/tabs/search-api-page.vue');

const jobs = () => import('@/pages/admin/tabs/jobs-page.vue');

const riskPolicy = () => import('@/pages/admin/tabs/risk-policy-page.vue');

const bomStatuses = () => import('@/pages/admin/tabs/bom-statuses/bom-statuses-page.vue');

const integrations = () => import('@/pages/admin/tabs/integrations/integrations-page.vue');

const workspaceDetails = () => import('@/pages/workspace-details/workspace-details-page.vue');

const boms = () => import('@/pages/boms/boms-page.vue');

const home = () => import('@/pages/home/home-page.vue');

const productDashboard = () => import('@/pages/product-dashboard/product-dashboard-page.vue');

const hunterList = () => import('@/pages/hunter/hunter-list-page/hunter-list-page.vue');

const hunterDetailsPage = () => import('@/pages/hunter/hunter-details-page/hunter-details-page.vue');

const ignoreRules = () => import('@/pages/ignore-rules/ignore-rules-page.vue');

const login = () => import('@/pages/guest/login/login-page.vue');

const componentBrowser = () => import('@/pages/component-browser/component-browser-page/component-browser-page.vue');

const componentBrowserItemDisplay = () => import('@/pages/component-browser/item-details-page/item-details-page.vue');

const componentBrowserResults = () => import('@/pages/component-browser/items-results-page/items-results-page.vue');

const pinnedItems = () => import('@/pages/component-browser/pinned-items-page/pinned-items-page.vue');

const parametricSearch = () => import('@/pages/component-browser/parametric-search-page/parametric-search-page.vue');

const notFound = () => import('@/pages/not-found/not-found-page.vue');

const hunterUpgrade = () => import('@/pages/upgrade/hunter-upgrade-page.vue');

const productUpgrade = () => import('@/pages/upgrade/product-upgrade-page.vue');

const bomsUpgrade = () => import('@/pages/upgrade/boms-upgrade-page.vue');

const register = () => import('@/pages/guest/register/register-page.vue');

const registrationFinished = () => import('@/pages/guest/registration-finished/registration-finished-page.vue');

const bomOptimization = () => import('@/pages/bom-optimization/bom-optimization-page.vue');

const mobileUnsupportMessage = () => import('@/pages/mobile-unsupport-message/mobile-unsupport-message.vue');

const welcomeStage = () => import('@/pages/welcome-stage/welcome-stage-page.vue');

const ordersList = () => import('@/pages/orders/orders-page.vue');

const orderDetails = () => import('@/pages/orders/order-details-page.vue');

Vue.use(VueRouter);
const router = new VueRouter({
  routes: [{
    path: '/login',
    alias: '/reset_password',
    name: 'login',
    component: login,
    props: true,
    meta: {
      requiresAuth: false,
      middleware: guestRedirectMiddleware,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    props: true,
    meta: {
      requiresAuth: false,
      middleware: [guestRedirectMiddleware, registerAllowedMiddleware],
    },
  },
  {
    path: '/registration-finished',
    name: 'registration-finished',
    component: registrationFinished,
    props: true,
    meta: {
      requiresAuth: false,
      middleware: [guestRedirectMiddleware, registerAllowedMiddleware],
    },
  },
  {
    path: '/home',
    name: 'home',
    component: home,
    meta: {
      middleware: [authMiddleware, homeMiddleware],
    },
  },
  {
    path: '/component-browser',
    name: 'componentBrowser',
    component: componentBrowser,
    meta: {
      middleware: authMiddleware,
    },
  },
  {
    path: '/component-browser/:searchKeyword/display/:componentId/:componentMfrName?',
    name: 'componentDisplay',
    component: componentBrowserItemDisplay,
    meta: {
      middleware: authMiddleware,
    },
  },
  {
    path: '/component-browser/:itemSearch/results',
    name: 'componentResults',
    component: componentBrowserResults,
    meta: {
      middleware: authMiddleware,
    },
  },
  {
    path: '/component-browser/:category/advance-search',
    name: 'parametricSearch',
    component: parametricSearch,
    props: true,
    meta: {
      keepAlive: true,
      middleware: [parametricSearchMiddleware, authMiddleware],
    },
  },
  {
    path: '/component-browser/pinned-items',
    name: 'pinned-items',
    component: pinnedItems,
    props: true,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/boms',
    name: 'boms',
    component: boms,
    meta: {
      keepAlive: true,
      middleware: [
        authMiddleware, scansMiddleware,
      ],
    },
  },
  {
    path: '/upgrade/boms',
    name: 'upgrade-to-boms',
    component: bomsUpgrade,
    meta: {
      middleware: [authMiddleware, scansUpgradeMiddleware],
    },
  },
  {
    path: '/hunter',
    name: 'hunter',
    component: hunterList,
    meta: {
      middleware: [
        authMiddleware, hunterMiddleware,
      ],
    },
  },
  {
    path: '/hunter/:workspaceId',
    name: 'hunterDetails',
    props: true,
    component: hunterDetailsPage,
    meta: {
      middleware: [
        authMiddleware, hunterMiddleware,
      ],
    },
  },
  {
    path: '/upgrade/hunter',
    name: 'upgrade-to-hunter',
    component: hunterUpgrade,
    meta: {
      middleware: [authMiddleware, hunterUpgradeMiddleware],
    },
  },
  {
    path: '/ignoreRules',
    name: 'ignoreRules',
    component: ignoreRules,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: [authMiddleware, ignoreRulesMiddleware],
    },
  },
  {
    path: '/products',
    name: 'products',
    component: productDashboard,
    meta: {
      keepAlive: true,
      middleware: [
        authMiddleware, productsMiddleware,
      ],
    },
  },
  {
    path: '/upgrade/products-dashboard',
    name: 'upgrade-to-product',
    component: productUpgrade,
    meta: {
      middleware: [authMiddleware, productsUpgradeMiddleware],
    },
  },
  {
    path: '/workspace/:workspaceId',
    props: true,
    name: 'workspaceDetails',
    component: workspaceDetails,
    meta: {
      middleware: authMiddleware,
    },
  },
  {
    path: '/data-manager',
    redirect: '/data-manager/repository-browser',
  },
  {
    path: '/data-manager/repository-browser',
    name: 'repositoryBrowser',
    component: repositoryBrowser,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/admin/integrations',
    name: 'integrations',
    component: integrations,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/admin/settings',
    name: 'settings',
    component: settings,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/data-manager/mfrInfo',
    name: 'mfrInfo',
    component: mfrInfo,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/data-manager/techData',
    name: 'techData',
    component: techData,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/data-manager/searchAPI',
    name: 'searchAPI',
    component: searchAPI,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/admin/jobs',
    name: 'jobs',
    component: jobs,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: authMiddleware,
    },
  },
  {
    path: '/admin/riskPolicy',
    name: 'riskPolicy',
    component: riskPolicy,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: [authMiddleware],
    },
  },
  {
    path: '/admin/bom-statuses',
    name: 'bom_statuses',
    component: bomStatuses,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/admin/general',
    name: 'general_settings',
    component: adminGeneral,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: [authMiddleware],
    },
  },
  {
    path: '/admin/users',
    name: 'users',
    component: adminUsers,
    meta: {
      appBarColor: '#F4F7F9',
      middleware: [authMiddleware],
    },
  },
  {
    path: '/bom-optimization/:workspaceId',
    name: 'bom_optimization',
    component: bomOptimization,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/orders',
    name: 'orders',
    component: ordersList,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/orders/:orderId',
    name: 'orderDetails',
    props: true,
    component: orderDetails,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/welcome',
    name: 'welcome-stage',
    component: welcomeStage,
    meta: {
      middleware: [authMiddleware],
    },
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: notFound,
  },
  {
    path: '/:notFound(.*)',
    redirect: '/home',
  },
  {
    path: '/mobile-not-supported',
    name: 'mobile-not-supported',
    component: mobileUnsupportMessage,
    meta: {
      middleware: [mobileUnsupportMiddleware],
    },
  },
  ],
});
router.beforeEach((to, from, next) => {
  if (from?.name !== to.name) {
    sendTrackingEvent(`open_${to.name}`);
  }

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router;
