import store from '@/store/store';

export const authenticated = () => store.state.auth.authenticated;
export const username = () => store.state.auth.username;
export const myAvatar = () => store.state.auth.userAvatar;
export const fullName = () => store.state.auth.fullName;
export const org = () => store.state.auth.org;
export const isOrgAdmin = () => store.state.auth.org_admin;
export const isDataAdmin = () => store.state.auth.data_admin;
export const isSiteAdmin = () => store.state.auth.site_admin;
export const isUsersManagement = () => store.state.auth.users_management;
export const isPolicyManagement = () => store.state.auth.policy_management;
export const isMultiOrg = () => store.state.auth.org === '*';
export const isVendorRoleEnabled = () => store.state.auth.roles.includes('vendor');
