var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav sidebar-nav nav-items"},[(_vm.homeEnabled)?_c('SideBarPageLink',{ref:"home_page_route",attrs:{"id":"home_tab","route":"/home","icon":"fas fa-home fa-icon","isBackgroundHighlight":"","title":"Home"}}):_vm._e(),(_vm.compSearchEnabled)?_c('SideBarPageLink',{ref:"component_browser_page_route",attrs:{"isBackgroundHighlight":"","id":"component_browser_tab","route":"/component-browser","icon":"/images/component-browser-unselected.svg","light-icon":"/images/component-browser-selected.svg","title":"Component Browser","child-route-names":[
      'componentBrowser',
      'componentResults',
      'componentDisplay',
      'parametricSearch',
    ]}}):_vm._e(),_c('SideBarPageLink',{ref:"boms_page_route",attrs:{"indicate-premium":!_vm.scansEnabled,"isBackgroundHighlight":"","id":"scans_tab","route":"/boms","icon":"fas fa-microchip fa-icon","title":"Scans","child-route-names":[
      'upgrade-to-boms',
    ]}}),_c('SideBarPageLink',{ref:"hunts_page_route",attrs:{"indicate-premium":!_vm.huntsEnabled,"isBackgroundHighlight":"","id":"hunter_tab","route":"/hunter","icon":"fas fa-crosshairs fa-icon","title":"Hunter","child-route-names":[
      'upgrade-to-hunter',
    ]}}),(_vm.isOrderSupportEnabled && !_vm.isMultiOrg)?_c('SideBarPageLink',{ref:"orders_page_route",attrs:{"isBackgroundHighlight":"","id":"orders_tab","route":"/orders","icon":"/images/orders-unselected.svg","lightIcon":"/images/orders-selected.svg","title":"Orders","child-route-names":[
      'orders',
      'orderDetails',
    ]}}):_vm._e(),(_vm.ignoreRulesEnabled)?_c('SideBarPageLink',{ref:"ignore_rules_page_route",attrs:{"isBackgroundHighlight":"","id":"ignore_rules_tab","route":"/ignoreRules","icon":"fas fa-shield-halved fa-icon","title":"Ignore Rules"}}):_vm._e(),(_vm.org !== '*')?_c('SideBarPageLink',{ref:"products_page_route",attrs:{"indicate-premium":!_vm.productsInsightsEnabled,"isBackgroundHighlight":"","id":"products_tab","route":"/products","icon":"fas fa-chart-line fa-icon","title":"Products","child-route-names":[
      'upgrade-to-product',
    ]}}):_vm._e(),(_vm.isDataAdmin)?_c('SideBarPageLink',{ref:"data_manager_route",attrs:{"isBackgroundHighlight":"","id":"data_manager","route":"/data-manager","icon":"fas fa-database fa-icon","title":"Data Manager"}}):_vm._e(),(_vm.adminEnabled)?_c('SideBarPageLink',{ref:"admin_route_link",attrs:{"isBackgroundHighlight":"","id":"admin_tab"}},[_c('span',{ref:"admin_route_span",staticClass:"route-path",class:_vm.isInAdmin?'active':'',attrs:{"slot":"content"},on:{"click":function($event){return _vm.goToAdmin()}},slot:"content"},[_c('div',{staticClass:"route-link-wrap"},[_c('i',{staticClass:"fas fa-cog fa-icon"}),_c('span',[_vm._v("Admin")])])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }